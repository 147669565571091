import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'app-branding',
  standalone: true,
  imports: [NgIf, RouterModule],
  template: `
    <div class="branding">
      @if(options.theme === 'light') {
      <a [routerLink]="['/dashboard']">
      <img src="./assets/images/logos/mailbit.svg" class="align-middle m-2 m-l-32" alt="logo" width="110"
      height="125" />
      </a>
      } @if(options.theme === 'dark') {
        <a [routerLink]="['/dashboard']">
        <img src="./assets/images/logos/mailbit.svg" class="align-middle m-2 m-l-32" alt="logo" width="110"
        height="125" />
        </a>
      }
    </div>
  `,
})
export class BrandingComponent {
  options = this.settings.getOptions();

  constructor(private settings: CoreService) {}
}

import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SoftwareUpdateService } from './services/update/update.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isLoading: any = false;

  constructor(private _router: Router,
    private softwareUpdateService: SoftwareUpdateService,
  ) { }

  ngOnInit() {
    if (environment.production === true) {
    //this.softwareUpdateService.initApplicationUpdating();
    }
    this._router.events.subscribe(v => this.navigationInterceptor(v));
  }

  navigationInterceptor(event: any): void {
    if (event instanceof NavigationStart) {
      this.isLoading = true;
    }
    if (event instanceof NavigationEnd) {
      this.isLoading = false;
    }
    if (event instanceof NavigationCancel) {
      this.isLoading = false;
    }
    if (event instanceof NavigationError) {
      this.isLoading = false;
    }
  }
}

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// icons
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

// perfect scrollbar
import { NgScrollbarModule } from 'ngx-scrollbar';

//Import all material modules
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//Import Layouts
import { BlankComponent } from './layouts/blank/blank.component';

import { FilterPipe } from './pipe/filter.pipe';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor } from './services/helpers/auth.interceptor';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { authDashReducer, authReducer } from './state/auth/auth.reducer';
import { priceReducer } from './state/price/price.reducer';
import { userReducer } from './state/user/user.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AuthValidateService } from './services/authentication/auth-validate.service';
import { TrackingPixelComponent } from './pages/pages/tracking_pixel/tracking_pixe';
import { UpdatePromptDialogComponent } from './services/update/update.service';
import { ErrorInterceptor } from './services/helpers/error.interceptor';
import { FullComponent } from './layouts/full/full.component';

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Define a simplified localStorageSyncReducer function
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  // Directly return the localStorageSync applied reducer without platform checks
  return localStorageSync({ keys: ['auth'], rehydrate: true })(reducer);
}

export function initializeApp(authValidateService: AuthValidateService) {
  return (): Promise<boolean> => {
    return authValidateService.validateTokenAndInitializeApp();
  };
}

// Update the metaReducers array to use the simplified reducer function
const metaReducers: MetaReducer<any>[] = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent, BlankComponent, FilterPipe, TrackingPixelComponent, UpdatePromptDialogComponent],
  imports: [
    BrowserModule,
    //AppRoutingModule.routing,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TablerIconsModule.pick(TablerIcons),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgScrollbarModule,
    FullComponent,
    StoreModule.forRoot({ auth: authReducer, dashauth: authDashReducer, user: userReducer, prices: priceReducer }, { metaReducers }),
    MatSelectCountryModule.forRoot('en'),
  ],
  exports: [TablerIconsModule],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthValidateService],
      multi: true,
    },
  ],
})
export class AppModule { }

<div *ngIf="isLoading" class="overflow-hidden banner-section">
    <div class="card overflow-hidden d-flex justify-content-center align-items-center text-center"
        style="height: 100vh;">
        <div>
            <div class="row justify-content-center">
                <mat-spinner diameter="30" color="primary"></mat-spinner>
            </div>
            <h4 class="mt-3 text-secondary">Please wait while the page is loading...</h4>
        </div>
    </div>
</div>


<router-outlet *ngIf="!isLoading"></router-outlet>
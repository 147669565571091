export const navItems: any[] = [
  {
    navCap: 'ADMIN',
    role: 'admin'
  },
  {
    displayName: 'Admin',
    iconName: 'settings-plus',
    children: [
      {
        displayName: 'Generate Email Report',
        iconName: 'report',
        route: '/dashboard/admin/report-generate',
        role: 'admin'
      },
      {
        displayName: 'User Management',
        iconName: 'user',
        route: '/dashboard/admin/user-settings',
        role: 'admin'
      },
      {
        displayName: 'Domain Management',
        iconName: 'globe',
        route: '/dashboard/admin/domains',
        role: 'admin'
      },
      {
        displayName: 'Campaign Approvals',
        iconName: 'mail-search',
        route: '/dashboard/admin/campaign-management',
        role: 'admin'
      },
      {
        displayName: 'Content Check',
        iconName: 'mail-check',
        route: '/dashboard/admin/email-analysis',
        role: 'admin'
      },
      {
        displayName: 'Anti Spam Control',
        iconName: 'mail-check',
        route: '/dashboard/admin/anti-spam',
        role: 'admin'
      },
      {
        displayName: 'IP Management',
        iconName: 'access-point',
        route: '/dashboard/admin/ip-management',
        role: 'admin'
      },
    ],
  },
  {
    navCap: 'EVENT',
    role: 'event'
  },
  {
    displayName: 'Event Management',
    iconName: 'ticket',
    children: [
      {
        displayName: 'Ticket Scanner',
        iconName: 'scan',
        route: '/dashboard/ticket-scanner',
        role: 'event'
      },
      {
        displayName: 'Invite Scanner',
        iconName: 'users',
        route: '/dashboard/invite-scanner',
        role: 'eventOwner'
      },
      {
        displayName: 'Send VIP Tickets',
        iconName: 'ticket',
        route: '/dashboard/vip-ticket',
        role: 'eventOwner'
      },
    ],
  },
  {
    navCap: 'DASHBOARD',
    role: 'user'
  },
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    children: [
      {
        displayName: 'Overview',
        iconName: 'dashboard',
        route: '/dashboard',
        role: 'user'
      },
    ],
  },
  {
    navCap: 'MARKETING & CONTACT',
    role: 'user'
  },
  {
    displayName: 'Marketing & Contact',
    iconName: 'mail-forward',
    children: [
      {
        displayName: 'Manage Marketing',
        iconName: 'mail-forward',
        route: '/dashboard/email-marketing',
        role: 'user'
      },
    ],
  },
  {
    navCap: 'EMAIL VALIDATION',
    role: 'user'
  },
  {
    displayName: 'Email',
    iconName: 'mail-forward',
    children: [
      {
        displayName: 'Email Validation',
        iconName: 'mail-plus',
        route: '/dashboard/email-validation',
        role: 'user'
      }
    ],
  },
  {
    navCap: 'CONNECTION',
    role: 'user'
  },
  {
    displayName: 'Domain & SMTP Setup',
    iconName: 'world',
    children: [
      {
        displayName: 'Domain & SMTP & API',
        iconName: 'world',
        route: '/dashboard/connection-settings',
        role: 'user'
      },
    ],
  },
  {
    navCap: 'EMAIL ACTIVITY',
    role: 'user'
  },
  {
    displayName: 'Email Activity',
    iconName: 'list',
    route: '/dashboard/email-activity',
    role: 'user'
  },
  {
    navCap: 'PLANS & PURCHASES',
    role: 'user'
  },
  {
    displayName: 'Upgrade & Buy Services',
    iconName: 'shopping-cart',
    children: [
      {
        displayName: 'Services',
        iconName: 'mail-up',
        route: '/dashboard/plans',
        role: 'user'
      },
    ],
  },
  {
    navCap: 'SETTINGS',
    role: 'user'
  },
  {
    displayName: 'Settings',
    iconName: 'settings',
    children: [
      {
        displayName: 'Account Settings',
        iconName: 'settings',
        route: '/dashboard/account-settings',
        role: 'user'
      },
    ],
  },
  {
    navCap: 'DOCUMENTATION',
    role: 'user'
  },
  {
    displayName: 'SMTP & API Docs',
    iconName: 'code',
    children: [
      {
        displayName: 'SMTP Docs',
        iconName: 'code',
        route: '/dashboard/documentation-smtp',
        role: 'user'
      },
      {
        displayName: 'API Docs',
        iconName: 'api',
        route: '/dashboard/documentation-api',
        role: 'user'
      },
    ],
  },
  {
    navCap: 'SUPPORT',
    role: 'user'
  },
  {
    displayName: 'Support',
    iconName: 'help',
    children: [
      {
        displayName: 'Chat Online',
        iconName: 'message',
        route: '/dashboard/chat',
        role: 'user'
      },
      {
        displayName: 'Contact Us',
        iconName: 'help',
        route: '/dashboard/contact',
        role: 'user'
      },
    ],
  },
];

import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { SubdomainService } from './services/subdomain.service';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { NavBarComponent } from './pages/pages/components/navbar/navbar.component';
import { NavBarTicketComponent } from './pages/fashion/components/navbar/navbar.component';
import { TrackingPixelComponent } from './pages/pages/tracking_pixel/tracking_pixe';

export function getRoutes(subdomainService: SubdomainService): Routes {
  return [
    {
      path: '',
      component: FullComponent,
      children: [
        {
          path: '',
          redirectTo: '/pages/home',
          pathMatch: 'full',
        },
        {
          path: 'dashboard',
          loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        },
      ],
    },
    {
      path: '',
      component: BlankComponent,
      children: [
        {
          path: 'pages',
          component: subdomainService.isFashionSubdomain() ? NavBarTicketComponent : NavBarComponent,
          loadChildren: () => subdomainService.isFashionSubdomain()
            ? import('./pages/fashion.module').then(m => m.FashionModule)
            : import('./pages/pages.module').then(m => m.PagesModule),
        },
      ],
    },
    {
      path: 'content/images/load/:trackingId.png',
      component: TrackingPixelComponent,
    },
    {
      path: '**',
      redirectTo: 'pages/home',
    },
  ];
}

@NgModule({
  imports: [RouterModule.forRoot(getRoutes(new SubdomainService()))],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(router: Router) {
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      window.scrollTo(0, 0);
    });
  }
}

export const menuData: any[] = [
    { title: 'Home', link: '/pages/home', authenticated: null, showInMobileMenu: true },
    { title: 'SMTP Relay', link: '/pages/smtp', authenticated: null, showInMobileMenu: true },
    { title: 'Email Marketing', link: '/pages/marketing', authenticated: null, showInMobileMenu: true },
    { title: 'Email Validation', link: '/pages/email-validation', authenticated: null, showInMobileMenu: true },
    { title: 'Pricing', link: '/pages/pricing', authenticated: null, showInMobileMenu: true },
    { title: 'Contact Us', link: '/pages/contact', authenticated: null, showInMobileMenu: true },
    { title: 'FAQ', link: '/pages/faq', authenticated: null, showInMobileMenu: true },
    { title: 'Access Dashboard', link: '/dashboard', authenticated: true, showInMobileMenu: true, accent: true },
    { title: 'Login', link: '/pages/login', authenticated: false, showInMobileMenu: true },
    { title: 'Start For Free', link: '/pages/sign', authenticated: false, showInMobileMenu: true, accent: true },
];

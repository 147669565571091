import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.state';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectUserData = createSelector(
  selectUserState,
  (state: UserState) => state?.user || {}
);

export const selectUserPrivileges = createSelector(
  selectUserState,
  (state: UserState) => state?.user_privileges || {}
);

export const selectUserDomains = createSelector(
  selectUserState,
  (state: UserState) => state?.user_domains || []
);

export const selectUserSMTPs = createSelector(
  selectUserState,
  (state: UserState) => state?.user_smtp || []
);

export const selectUserSenders = createSelector(
  selectUserState,
  (state: UserState) => state?.user_sender || []
);

export const selectUserCredits = createSelector(
  selectUserState,
  (state: UserState) => state?.user_credits || []
);

export const selectUserEmailLogs = createSelector(
  selectUserState,
  (state: UserState) => state?.user_email_logs || []
);

export const selectAdminUserEmailLogs = createSelector(
  selectUserState,
  (state: UserState) => state?.admin_user_email_logs || []
);

export const selectUserAccessControl = createSelector(
  selectUserState,
  (state: UserState) => state?.user_access_control || []
);

export const selectUserEmailAnalitycsControl = createSelector(
  selectUserState,
  (state: UserState) => state?.user_analytics || []
);

export const selectUserEmailRealAnalitycsControl = createSelector(
  selectUserState,
  (state: UserState) => state?.user_analytics_real || []
);

export const selectAdminDomains = createSelector(
  selectUserState,
  (state: UserState) => state?.admin_domains || []
);

export const selectAdminCampaigns = createSelector(
  selectUserState,
  (state: UserState) => state?.admin_campaigns || []
);

export const selectAdminEmailAnalysis = createSelector(
  selectUserState,
  (state: UserState) => state?.admin_email_analysis || []
);


//selectUserEvents
export const selectUserEvents = createSelector(
  selectUserState,
  (state: UserState) => state?.user_events || []
);

export const selectUserUnlayerDesigns = createSelector(
  selectUserState,
  (state: UserState) => state?.user_unlayer_designs || []
);

export const selectUserContactList = createSelector(
  selectUserState,
  (state: UserState) => state?.user_contact_list || []
);

export const selectUserEmailValidationList = createSelector(
  selectUserState,
  (state: UserState) => state?.user_email_validation_list || []
);

export const selectStockTemplates = createSelector(
  selectUserState,
  (state: UserState) => state?.stock_templates || []
);

export const selectUserCampaings = createSelector(
  selectUserState,
  (state: UserState) => state?.user_campaings || []
);


export const selectUserHasMore = createSelector(
  selectUserState,
  (state: UserState) => state?.user_has_more || null
);

export const selectUserApiKeys = createSelector(
  selectUserState,
  (state: UserState) => state?.user_api_keys || []
);
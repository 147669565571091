import { createReducer, on } from '@ngrx/store';
import { setAuthenticationStatus,/*, setPermissions */
setDashboardAuthenticationStatus} from './auth.actions';
import { initialState } from './auth.state';

export const authReducer = createReducer(
  initialState,
  on(setAuthenticationStatus, (state, { isAuthenticated }) => ({
    ...state,
    isAuthenticated,
  })),
);

export const authDashReducer = createReducer(
  initialState,
  on(setDashboardAuthenticationStatus, (state, { isAuthenticated }) => ({
    ...state,
    isAuthenticated,
  })),
);
export const appsData: any[] = [
  /*{
    id: 1,
    img: "/assets/images/menu/send-message.png",
    title: "SMTP Server",
    subtitle: "Seamless Email Sending Solutions",
    link: "/pages/smtp"
  },
  {
    id: 1,
    img: '/assets/images/menu/inbox.png',
    title: 'Inbox Placement',
    subtitle: 'The most accurate spam test to uncover where your emails are landing',
    link: '/pages/inbox-placement',
  },*/
  {
    id: 2,
    img: '/assets/images/menu/inbox.png',
    title: 'Inbox or Spam',
    subtitle: 'Ensure Your Emails Always Reach the Inbox',
    link: '/pages/email-testing',
  },
  /*{
    id: 3,
    img: '/assets/images/menu/online-security.png',
    title: 'SPF Configuration Tester',
    subtitle: 'Secure Your Email’s Journey',
    link: '/pages/email-testing',
  },
  {
    id: 4,
    img: '/assets/images/menu/verified.png',
    title: 'DKIM & DMARC Inspector',
    subtitle: 'Fortify Your Email Authenticity',
    link: '/pages/email-testing',
  },*/
  {
    id: 5,
    img: '/assets/images/menu/spam.png',
    title: 'Blocklist Checker',
    subtitle: 'Verify if Your Sender IP Address or Domain is Blocklisted',
    link: '/pages/blocklist',
  },
  /*{
    id: 6,
    img: '/assets/images/menu/fire.png',
    title: 'IP/Domain Warm-Up',
    subtitle: 'Build Trust and Deliverability with Gradual Sending',
    link: '/pages/soon',
  },*/
  /* {
     id: 7,
     img: '/assets/images/menu/email-check.png',
     title: 'Email Address Validator',
     subtitle: 'Ensure Deliverability and Engagement with Accurate Email Verification',
     link: '/pages/soon',
   },*/
];

<mat-sidenav-container>
  <mat-sidenav-content>
    <!-- ------------------------------------------------------------------
    Header
    ------------------------------------------------------------------ -->
    <mat-toolbar class="topbar topbar-xl m-auto">
      <div class="container full-width p-xs-6">
        <!-- --------------------------------------------------------------- -->
        <div class="branding p-x-0">
          @if(options.theme === 'light') {
          <a [routerLink]="['/pages/home']">
            <img src="./assets/images/logos/mailbit.svg" class="align-middle m-2" alt="logo" width="100" height="100" />
          </a>

          } @if(options.theme === 'dark') {
          <a [routerLink]="['/pages/home']">
            <img src="./assets/images/logos/mailbit.svg" class="align-middle m-2" alt="logo" width="100" height="100" />
          </a>
          }
        </div>

        <!-- --------------------------------------------------------------- -->

        <span class="flex-1-auto"></span>

        <!-- <div class="d-none d-lg-flex">

          <button mat-button class="m-x-8 f-s-16 f-w-500" [matMenuTriggerFor]="appsmenu" aria-label="Notifications">
            <div class="d-flex align-items-center">
              Email Tools
              <i-tabler name="chevron-down" class="icon-16 m-l-4"></i-tabler>
            </div>
          </button>
          <mat-menu #appsmenu="matMenu" class="apps-dd cardWithShadow" xPosition="before">
            <div class="row">
              <div class="col-lg-12">
                <div class="p-32 p-b-0">
                  <div class="row">
                    @for(appdd of apps; track appdd.title) {
                    <div class="col-sm-6 text-hover-primary">
                      <a [routerLink]="[appdd.link]" class="d-flex align-items-center text-decoration-none m-b-24">
                        <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
                          <img [src]="appdd.img" width="20" />
                        </button>

                        <div class="m-l-16">
                          <h5 class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text">
                            {{ appdd.title }}
                          </h5>
                          <span class="mat-body-1 f-s-12">{{
                            appdd.subtitle
                            }}</span>
                        </div>
                      </a>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </mat-menu>
        </div>
-->
        <div *ngFor="let item of menuItems" class="d-none d-lg-flex">
          <ng-container *ngIf="item.authenticated === null || item.authenticated === !!userData?.id">
            <!-- For items without the 'accent' property or when 'accent' is false -->
            <a *ngIf="!item.accent" mat-button class="m-x-8 f-s-16 f-w-500" href="javascript:void(0)"
              [routerLink]="item.link">{{item.title}}</a>

            <!-- For the item with the 'accent' property set to true -->
            <a *ngIf="item.accent" mat-flat-button color="accent" class="m-l-10" href="javascript:void(0)"
              [routerLink]="item.link">{{item.title}}</a>
          </ng-container>
        </div>



        <!-- Mobile Menu -->
        <button mat-icon-button (click)="filterNavRight.toggle()" class="d-flex d-lg-none">
          <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
        </button>
      </div>
    </mat-toolbar>

    <!-- ------------------------------------------------------------------
   End Header
    ------------------------------------------------------------------ -->

    <!-- ------------------------------------------------------------------
    buy section
    ------------------------------------------------------------------ -->
    <router-outlet></router-outlet>
    <!-- ------------------------------------------------------------------
    footer
    ------------------------------------------------------------------ -->

    <!-- ------------------------------------------------------------------
    end footer
    ------------------------------------------------------------------ -->
  </mat-sidenav-content>

  <!-- ------------------------------------------------------------------
    Mobile Sidebar
    ------------------------------------------------------------------ -->
  <mat-sidenav #filterNavRight mode="over" position="end" class="filter-sidebar">
    <div>
      <div class="branding">
        @if(options.theme === 'light') {
        <a [routerLink]="['/pages/home']">
          <img src="./assets/images/logos/mailbit.svg" class="align-middle m-2" alt="logo" width="100" height="100" />
        </a>
        } @if(options.theme === 'dark') {
        <a [routerLink]="['/pages/home']">
          <img src="./assets/images/logos/mailbit.svg" class="align-middle m-2" alt="logo" width="100" height="100" />
        </a>
        }
      </div> <!--
      <mat-accordion>
        <mat-expansion-panel class="shadow-none">
          <mat-expansion-panel-header>
            <mat-panel-title class="f-s-16 f-w-500"> Email Tools </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <div class="row">
              @for(appdd of apps; track appdd.img) {
              <div class="col-12 text-hover-primary">
                <a [routerLink]="[appdd.link]" class="d-flex align-items-center text-decoration-none m-b-24">
                  <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
                    <img [src]="appdd.img" width="20" />
                  </button>

                  <div class="m-l-16">
                    <h5 class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text">
                      {{ appdd.title }}
                    </h5>
                    <span class="mat-body-1 f-s-12">{{ appdd.subtitle }}</span>
                  </div>
                </a>
              </div>
              }
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
-->
      <div style="min-width: 250px;" class="p-x-16">
        <ng-container *ngFor="let item of menuItems">
          <!-- Show menu item if it should be displayed in the mobile menu -->
          <ng-container
            *ngIf="item.showInMobileMenu && ((item.authenticated && userData?.id) || (item.authenticated === false && !userData?.id) || item.authenticated === null)">
            <a *ngIf="!item.accent" href="javascript:void(0)" [routerLink]="item.link"
              class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16">
              <span class="f-s-15 f-w-500 m-l-8">{{ item.title }}</span>
            </a>
            <a *ngIf="item.accent" mat-flat-button [color]="item.authenticated ? 'primary' : 'accent'" class="w-100"
              href="javascript:void(0)" [routerLink]="item.link">{{ item.title }}</a>
          </ng-container>
        </ng-container>

      </div>

    </div>
  </mat-sidenav>
</mat-sidenav-container>

<app-page-footer-ticket></app-page-footer-ticket>
// price.reducer.ts
import { createReducer, on } from '@ngrx/store';
import * as PriceActions from './price.actions';
import { initialPriceState } from './price.state';

export const priceReducer = createReducer(
  initialPriceState,
  on(PriceActions.setPlatformTicketFee, (state, { fee }) => ({ ...state, platformTicketFee: fee })),
  on(PriceActions.setVatOnPlatformVat, (state, { vat }) => ({ ...state, vatOnPlatformFee: vat })),
  on(PriceActions.setTicketPercentage, (state, { percentage }) => ({ ...state, ticketPercentage: percentage }))
);

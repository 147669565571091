import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  OnInit,
} from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { navItems } from '../../items/sidebar-data';
import { Store } from '@ngrx/store';
import { UserStorageService } from 'src/app/services/storage/user-storage.service';
import { AuthState, DashBoardAuthState } from 'src/app/state/auth/auth.state';
import { selectUserData } from 'src/app/state/user/user.selector';
import { UserState } from 'src/app/state/user/user.state';
import { appsData } from '../../items/free_tools';
import { languagesData } from '../../items/languages';
import { profileData } from '../../items/profile';
import { setDashboardAuthenticationStatus } from 'src/app/state/auth/auth.actions';
import { logout } from 'src/app/state/user/user.action';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, CommonModule, NgScrollbarModule, TablerIconsModule, MaterialModule],
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {

  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();

  showFiller = false;

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: '/assets/images/flag/icon-flag-en.svg',
  };

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: '/assets/images/flag/icon-flag-en.svg',
    },
  ];

  userData: any;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private store: Store<UserState>,
    private storeAuth: Store<AuthState>,
    private storeDashAuth: Store<DashBoardAuthState>,
    private userStorageService: UserStorageService,
    private router: Router,
  ) {
    translate.setDefaultLang('en');


  }

  ngOnInit(): void {
    this.store.select(selectUserData).subscribe((userData: any) => {
      // Assign the user data to your component property
      this.userData = userData;
    });

    this.apps = appsData;
    this.profiledd = profileData;
    this.languages = languagesData;
  }

  openDialog() {
    const dialogRef = this.dialog.open(AppSearchDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  logout(): void {
    // Clear user data from local storage or cookies
    this.userStorageService.deleteUser();
    // Dispatch the logout action to clear NgRx state
    this.storeAuth.dispatch(logout());
    //
    this.storeAuth.dispatch(setDashboardAuthenticationStatus({ isAuthenticated: false }));
    // Redirect to the login page or home page
    this.router.navigate(['/pages/home']);
  }

  changeLanguage(lang: any): void {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

  notifications: any[] = [
    {
      id: 1,
      img: '/assets/images/profile/user-1.jpg',
      title: 'Roman Joined the Team!',
      subtitle: 'Congratulate him',
    },
  ];

  profiledd: any[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-account.svg',
      title: 'My Profile',
      subtitle: 'Account Settings',
      link: '/',
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-inbox.svg',
      title: 'My Inbox',
      subtitle: 'Messages & Email',
      link: '/apps/email/inbox',
    },
  ];

  apps: any[] = [
  ];

  quicklinks: any[] = [
    {
      id: 1,
      title: 'Pricing Page',
      link: '/theme-pages/pricing',
    },
  ];
}

@Component({
  selector: 'search-dialog',
  standalone: true,
  imports: [
    RouterModule,
    MaterialModule,
    TablerIconsModule,
    FormsModule
  ],
  templateUrl: 'search-dialog.component.html',
})
export class AppSearchDialogComponent {
  searchText: string = '';
  navItems = navItems;

  navItemsData = navItems.filter((navitem) => navitem.displayName);

  // filtered = this.navItemsData.find((obj) => {
  //   return obj.displayName == this.searchinput;
  // });
}

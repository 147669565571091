export const languagesData: any[] = [
    {
        language: 'English',
        code: 'en',
        type: 'US',
        icon: '/assets/images/flag/icon-flag-en.svg',
    },
    /*{
      language: 'Español',
      code: 'es',
      icon: '/assets/images/flag/icon-flag-es.svg',
    },
    {
      language: 'Français',
      code: 'fr',
      icon: '/assets/images/flag/icon-flag-fr.svg',
    },
    {
      language: 'German',
      code: 'de',
      icon: '/assets/images/flag/icon-flag-de.svg',
    },*/
];

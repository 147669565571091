import { Component, Output, EventEmitter, Input, ViewChild, OnInit } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { ViewportScroller } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { appsData } from 'src/app/layouts/full/items/free_tools';
import { UserState } from 'src/app/state/user/user.state';
import { Store } from '@ngrx/store';
import { selectUserData } from 'src/app/state/user/user.selector';
import { menuData } from 'src/app/layouts/full/items/menu';

@Component({
  selector: 'app-page-navbar',
  templateUrl: './navbar.component.html',
})
export class NavBarComponent implements OnInit {

  @ViewChild('filterNavRight') filterNavRight: MatSidenav;
  
  @Input() showToggle = true;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();

  options = this.settings.getOptions();

  apps: any[] = [];

  menuItems: any[] = [];

  userData: any;

  constructor(
    private settings: CoreService,
    private scroller: ViewportScroller,
    private router: Router,
    private store: Store<UserState>,
  ) {

  }


  ngOnInit(): void {
    // Close mobile sidebar on route change
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.closeMobileMenu();
      }
    });

    this.apps = appsData;
    this.menuItems = menuData;

    this.store.select(selectUserData).subscribe((userData: any) => {
      // Assign the user data to your component property
      this.userData = userData;
    });

  }

  closeMobileMenu(): void {
    if (this.filterNavRight) {
      this.filterNavRight.close();
    }
  }

   // scroll to demos
   gotoDemos() {
    this.scroller.scrollToAnchor('demos');
  }


}

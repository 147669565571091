import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { catchError, map } from 'rxjs';
import { API_ROUTES } from 'src/app/api/api-routes';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserStorageService } from '../storage/user-storage.service';
import { setAuthenticationStatus } from 'src/app/state/auth/auth.actions';
import { AuthState } from 'src/app/state/auth/auth.state';
import { storeUserData } from 'src/app/state/user/user.action';

@Injectable({
  providedIn: 'root'
})
export class AuthValidateService {
  constructor(
    private http: HttpClient,
    private store: Store<AuthState>,
    private userStorageService: UserStorageService,
  ) { }

  validateTokenAndInitializeApp(): Promise<boolean> {
    
    const token = this.userStorageService.getUser()?.token;

    if (!token) {
      this.store.dispatch(setAuthenticationStatus({ isAuthenticated: false }));
      return Promise.resolve(false);
    }

    const tokenValidateRoute = API_ROUTES.API + API_ROUTES.AUTH_TOKEN_VALIDATE;

    return this.http.post(tokenValidateRoute, { token }).toPromise().then((response: any) => {
      const jwt = new JwtHelperService();
      const decodedToken = jwt.decodeToken(token);
      const data = response;
      this.store.dispatch(storeUserData({ user: data.user }));
      // Uncomment and adjust if you have permissions to set in your decoded token
      // this.store.dispatch(setPermissions({ permissions: decodedToken.permissions }));
      this.store.dispatch(setAuthenticationStatus({ isAuthenticated: true }));
      return true;
    }).catch(err => {
      this.store.dispatch(setAuthenticationStatus({ isAuthenticated: false }));
      this.userStorageService.deleteUser();
      console.log('Error validating token', err);
      return false;
    });
  }
}

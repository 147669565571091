import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { CoreService } from 'src/app/services/core.service';
import { AppSettings } from 'src/app/app.config';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { AppNavItemComponent } from './vertical/sidebar/nav-item/nav-item.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './vertical/sidebar/sidebar.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TablerIconsModule } from 'angular-tabler-icons';
import { HeaderComponent } from './vertical/header/header.component';
import { AppHorizontalHeaderComponent } from './horizontal/header/header.component';
import { AppHorizontalSidebarComponent } from './horizontal/sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './shared/customizer/customizer.component';
import { selectUserData } from 'src/app/state/user/user.selector';
import { Store } from '@ngrx/store';
import { UserStorageService } from 'src/app/services/storage/user-storage.service';
import { DashBoardAuthState } from 'src/app/state/auth/auth.state';
import { UserState } from 'src/app/state/user/user.state';
import { navItems } from './items/sidebar-data';
import { appsData } from './items/free_tools';
import { setDashboardAuthenticationStatus } from 'src/app/state/auth/auth.actions';
import { logout } from 'src/app/state/user/user.action';

const MOBILE_VIEW = 'screen and (max-width: 768px)';
const TABLET_VIEW = 'screen and (min-width: 769px) and (max-width: 1024px)';
const MONITOR_VIEW = 'screen and (min-width: 1024px)';
const BELOWMONITOR = 'screen and (max-width: 1023px)';

@Component({
  selector: 'app-full',
  standalone: true,
  imports: [
    RouterModule,
    AppNavItemComponent,
    MaterialModule,
    CommonModule,
    SidebarComponent,
    NgScrollbarModule,
    TablerIconsModule,
    HeaderComponent,
    AppHorizontalHeaderComponent,
    AppHorizontalSidebarComponent,
    AppBreadcrumbComponent,
    CustomizerComponent
  ],
  templateUrl: './full.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class FullComponent implements OnInit, AfterViewChecked {

  navItems: any;
  filteredNavItems: any;
  userData: any;
  isAdmin: boolean = false;
  isEvent: boolean = false;
  isOwner: boolean = false;

  @ViewChild('leftsidenav')
  public sidenav: MatSidenav;
  resView = false;
  @ViewChild('content', { static: true }) content!: MatSidenavContent;
  options = this.settings.getOptions();
  private layoutChangesSubscription = Subscription.EMPTY;
  private isMobileScreen = false;
  private isContentWidthFixed = true;
  private isCollapsedWidthFixed = false;
  private htmlElement!: HTMLHtmlElement;

  get isOver(): boolean {
    return this.isMobileScreen;
  }

  get isTablet(): boolean {
    return this.resView;
  }

  apps: any[] = [];

  constructor(
    private settings: CoreService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private store: Store<UserState>,
    private storeAuth: Store<DashBoardAuthState>,
    private cdr: ChangeDetectorRef,
    private userStorageService: UserStorageService,
  ) {
    this.htmlElement = document.querySelector('html')!;
    this.layoutChangesSubscription = this.breakpointObserver
      .observe([MOBILE_VIEW, TABLET_VIEW, MONITOR_VIEW, BELOWMONITOR])
      .subscribe((state) => {
        this.options.sidenavOpened = true;
        this.isMobileScreen = state.breakpoints[MOBILE_VIEW];
        if (this.options.sidenavCollapsed == false) {
          this.options.sidenavCollapsed = state.breakpoints[TABLET_VIEW];
        }
        this.isContentWidthFixed = state.breakpoints[MONITOR_VIEW];
        this.resView = state.breakpoints[BELOWMONITOR];
      });

    this.receiveOptions(this.options);

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e) => {
        this.content.scrollTo({ top: 0 });
      });
  }

  ngOnInit(): void {
    this.apps = appsData;
    this.navItems = navItems;
    this.store.select(selectUserData).subscribe((userData: any) => {
      this.userData = userData;
      this.isAdmin = userData?.Roles?.some((role: { UserRole: { roleId: number; }; }) => role.UserRole?.roleId === 1);
      this.isEvent = userData?.accountType === 1;
      this.isOwner = userData?.wasInvited === false;
      this.filteredNavItems = this.filterNavItems(navItems);
    });
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
  

  ngOnDestroy() {
    this.layoutChangesSubscription.unsubscribe();
  }

  filterNavItems(items: any[]): any[] {
    return items
      .map(item => {
        if (item.children) {
          item.children = this.filterNavItems(item.children);
        }

        if (item.role === 'admin' && !this.isAdmin) return null;
        if (item.role === 'event' && !this.isEvent) return null;
        if (item.role === 'eventOwner' && (!this.isEvent || !this.isOwner)) return null;

        return item;
      })
      .filter(item => item !== null && (item.children === undefined || item.children.length > 0));
  }

  toggleCollapsed() {
    this.isContentWidthFixed = false;
    this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
    this.resetCollapsedState();
  }

  resetCollapsedState(timer = 400) {
    setTimeout(() => this.settings.setOptions(this.options), timer);
  }

  onSidenavClosedStart() {
    this.isContentWidthFixed = false;
  }

  onSidenavOpenedChange(isOpened: boolean) {
    this.isCollapsedWidthFixed = !this.isOver;
    this.options.sidenavOpened = isOpened;
    this.settings.setOptions(this.options);
  }

  receiveOptions(options: AppSettings): void {
    this.options = options;
    this.toggleDarkTheme(options);
  }

  toggleDarkTheme(options: AppSettings) {
    if (options.theme === 'dark') {
      this.htmlElement.classList.add('dark-theme');
      this.htmlElement.classList.remove('light-theme');
    } else {
      this.htmlElement.classList.remove('dark-theme');
      this.htmlElement.classList.add('light-theme');
    }
  }

  logout(): void {
    this.userStorageService.deleteUser();
    this.storeAuth.dispatch(logout());
    this.storeAuth.dispatch(setDashboardAuthenticationStatus({ isAuthenticated: false }));
    this.router.navigate(['/pages/home']);
  }
}

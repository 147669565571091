import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SubdomainService {
  subdomain: string;

  constructor() {
    const host = window.location.hostname;
    // Assuming your domain is always in the format of subdomain.domain.top-level-domain
    this.subdomain = host.split('.')[0];
  }

  isFashionSubdomain(): boolean {
    return this.subdomain === 'fashion';
  }
}

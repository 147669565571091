import { Component, Output, EventEmitter, ViewEncapsulation, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app.config';
import { CoreService } from 'src/app/services/core.service';
import { BrandingComponent } from '../../vertical/sidebar/branding.component';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-customizer',
  standalone: true,
  imports: [BrandingComponent, TablerIconsModule, MaterialModule, FormsModule, NgScrollbarModule, CommonModule],
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomizerComponent implements OnInit {
   
  @Output() optionsChange = new EventEmitter<AppSettings>();
  options: AppSettings;

  constructor(private settings: CoreService) {}

  ngOnInit() {
    this.loadOptions();
  }

  loadOptions() {
    const savedOptions = localStorage.getItem('appSettings');
    if (savedOptions) {
      this.options = JSON.parse(savedOptions);
    } else {
      this.options = this.settings.getOptions();
    }
    this.optionsChange.emit(this.options); // Emit the options on load
  }

  saveOptions() {
    localStorage.setItem('appSettings', JSON.stringify(this.options));
    this.optionsChange.emit(this.options);
  }

  setDark() {
    this.saveOptions();
  }

  setColor() {
    this.saveOptions();
  }

  setDir() {
    this.saveOptions();
  }

  setSidebar() {
    this.saveOptions();
  }

  isThemeSelected(theme: string): boolean {
    return this.options.activeTheme === theme;
  }

  isThemeHovered(theme: string): boolean {
    return this.options.activeTheme === theme || this.hoveredTheme === theme;
  }

  hoveredTheme: string = '';

  setHoveredTheme(theme: string) {
    this.hoveredTheme = theme;
  }

  clearHoveredTheme() {
    this.hoveredTheme = '';
  }
}

import { createAction, props } from '@ngrx/store';
import { User, UserPhone, UserDomain, UserSMTP, UserSender, UserCredits, UserEmailLogs, AccessControl, UserAnalytics,
   UserRealTimeAnalytics, AdminUserEmailLogs, AdminDomain, UserEvents, 
   UserUnlayerDesigns, UserContactList, StockTemplates, UserCampaing, AdminCampaign, UserHasMore, AdminEmailAnalysisGroup, UserApiKey, 
   UserEmailValidationList,
   UserPrivileges} from './user.state';

export const storeUserData = createAction(
  '[User] Store User Data',
  props<{ user?: User, user_privileges?: UserPrivileges, user_phones?: UserPhone | null, user_domains?: UserDomain[], user_smtp?: UserSMTP[], user_sender?: UserSender[],
     user_credits?: UserCredits, user_email_logs?: UserEmailLogs[], user_access_control?: AccessControl, user_analytics?: UserAnalytics[],
      user_analytics_real?: UserRealTimeAnalytics[], admin_user_email_logs?: AdminUserEmailLogs[], admin_domains?: AdminDomain[],
    user_events?: UserEvents[], user_unlayer_designs?: UserUnlayerDesigns[], 
    user_contact_list?: UserContactList[], 
    user_email_validation_list?: UserEmailValidationList[],
    user_has_more?: UserHasMore,
     stock_templates?: StockTemplates[], user_campaings?: UserCampaing[], user_api_keys?: UserApiKey[], admin_campaigns?: AdminCampaign[], admin_email_analysis?: AdminEmailAnalysisGroup[]  }>()
);

export const updateUserDomainStatus = createAction(
  '[User Domain] Update Status',
  props<{ id: number; validated: boolean, ownStatus?: any, keepChecking?: any }>()
);

export const addDomainSuccess = createAction(
  '[User Domain] Add Domain Success',
  props<{ domain: UserDomain }>() // Adjust types and structure as needed
);

export const removeUserDomain = createAction(
  '[User Domain] Remove Domain',
  props<{ id: number }>() // Use the unique identifier of the domain to remove
);

export const addSenderSuccess = createAction(
  '[User Sender] Add Sender Success',
  props<{ sender: UserSender }>() // Adjust types and structure as needed
);

export const updateSenderStatus = createAction(
  '[Sender] Update Status',
  props<{ id: number; validated: boolean }>()
);

export const updateSpamCredit = createAction(
  '[Spam Credit] Update Quantity',
  props<{ spamTestCredits: number }>()
);


export const removeUserSender = createAction(
  '[User Sender] Remove Sender',
  props<{ id: number }>() // Use the unique identifier of the domain to remove
);

export const addSmtpSuccess = createAction(
  '[User SMTP] Add SMTP Success',
  props<{ smtp: UserSMTP }>() // Adjust types and structure as needed
);

export const removeUserSmtp = createAction(
  '[User SMTP] Remove SMTP',
  props<{ id: number }>() // Use the unique identifier of the SMTP to remove
);

export const updateDomainStatus = createAction(
  '[Sender] Update Status',
  props<{ id: number; blocked: boolean, reason: string }>()
);

export const addUnlayerDesignSuccess = createAction(
  '[User Domain] Add Domain Success',
  props<{ unlayer: UserUnlayerDesigns }>() // Adjust types and structure as needed
);

export const updateUnlayerStatus = createAction(
  '[Unlayer] Update Status',
  props<{ id: number; screenshot: string }>()
);

export const addContactListSuccess = createAction(
  '[User Domain] Add Contact List Success',
  props<{ user_contact_list: UserContactList }>() // Adjust types and structure as needed
);

export const logout = createAction('[User] Logout');

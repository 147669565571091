<footer class="py-30 m-t-48">
  <div class="container">
    <div class="row justify-content-center text-center">
      <div class="col-md-3 mb-3">
        <img src="./assets/images/logos/mailbit.svg" alt="logo" width="80" height="80" class="mb-2">
        <div class="fs-6">
          All rights reserved by Mailbit. Designed & Developed by
          <a routerLink="/pages/home" class="mat-subtitle-2">Mailbit</a>.
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <h5>Legal</h5>
        <ul style="list-style-type: none; padding: 0;" class="text-center">
          <li class="m-b-4"><a routerLink="/pages/terms-conditions" class="mat-subtitle-2">Terms &
              Conditions</a></li>
          <li class="m-b-4"><a routerLink="/pages/private-policy" class="mat-subtitle-2">Privacy
              Policy</a></li>
          <li><a routerLink="/pages/gdpr" class="mat-subtitle-2">GDPR Compliance</a></li>
        </ul>
      </div>
      <div class="col-md-3 mb-3">
        <h5>Pages</h5>
        <ul style="list-style-type: none; padding: 0;" class="text-center">
          <li class="m-b-4"><a routerLink="/pages/home" class="mat-subtitle-2">Home</a></li>
          <li class="m-b-4"><a routerLink="/pages/pricing" class="mat-subtitle-2">Pricing</a></li>
          <li class="m-b-4"><a routerLink="/pages/pricing" class="mat-subtitle-2">FAQ</a></li>
          <li class="m-b-4"><a routerLink="/pages/contact" class="mat-subtitle-2">Help & Support</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3 mb-3">
        <h5>Apps</h5>
        <ul style="list-style-type: none; padding: 0;" class="text-center">
          <li class="m-b-4"><a routerLink="/pages/smtp" class="mat-subtitle-2">SMTP Relay</a></li>
          <li class="m-b-4"><a routerLink="/pages/marketing" class="mat-subtitle-2">Email Marketing</a></li>
          <li class="m-b-4"><a routerLink="/pages/email-testing" class="mat-subtitle-2">Run Spam Test</a>
          <li class="m-b-4"><a routerLink="/pages/blocklist" class="mat-subtitle-2">Blocklist Check</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
// software-update.service.ts
import { Component, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, interval, of } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { API_ROUTES } from 'src/app/api/api-routes';

@Injectable({ providedIn: 'root' })
export class SoftwareUpdateService {
    private currentTimestamp = '{{POST_BUILD_ENTERS_TIMESTAMP_HERE}}'; // This will be replaced post-build
    private appJsonFile = 'software.json';

    private updatePromptIsActive = false; // State to keep track of active update prompt

    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
    ) { }

    public initApplicationUpdating(): void {
        const frequency = 30000; // For example, check every 30000 milliseconds (or you can retrieve this from environment settings) 

        if (!frequency || frequency <= 0) {
            //console.warn("Invalid frequency: Skipping update checks.");
            return;
        }

        // Force initial check and reload if necessary
        this.runApplicationCheck(false);

        // Subsequent checks will ask the user before reloading
        interval(frequency).subscribe(() => this.runApplicationCheck(false));
    }

    private runApplicationCheck(forceReload: boolean = false): void {
        const url = `${API_ROUTES.FRONT_URL}${this.appJsonFile}?t=${new Date().getTime()}`;
        this.http.get(url).pipe(
            catchError(error => {
                this.showUpdatePrompt(true);
                // Handle the error here to prevent it from reaching the subscriber and stopping the observable.
                console.log(`Error fetching ${this.appJsonFile}: `);
                // Return a new, harmless observable that the subscriber can safely ignore.
                // This prevents the error from propagating further and stopping the subscription.
                return of(null); // 'of' is imported from 'rxjs'
            })
        ).subscribe(
            (response: any) => {
                if (response) { // Ensure response is not the harmless observable we might have returned!
                    const newTimestamp = response.timestamp;
                    const currentTs = String(this.currentTimestamp).trim();
                    const newTs = String(newTimestamp).trim();
                    //console.log(`Current timestamp: ${currentTs}`);
                    //console.log(`New timestamp: ${newTs}`);

                    if (newTs && newTs !== currentTs && !this.updatePromptIsActive) {
                        this.updatePromptIsActive = true;
                        this.showUpdatePrompt(forceReload);
                    } else {
                        console.log('No new updates found.');
                    }
                }
            },
            // This block will now no longer receive errors due to the catchError above.
            (error) => {
                // Even though this block won't receive the errors now, it's good practice to keep it.
                // In case you decide to handle certain errors differently in the catchError block.
            }
        );

    }

    private showUpdatePrompt(forceReload: boolean): void {
        const dialogRef = this.dialog.open(UpdatePromptDialogComponent, {
            disableClose: true // Prevent the dialog from being closed by clicking outside or pressing escape
        });

        dialogRef.afterClosed().subscribe(result => {
            this.updatePromptIsActive = false;
            if (result === true) {
                this.updateApplication(forceReload);
            } else {
                console.log('User declined the update.');
            }
        });
    }

    private updateApplication(forceReload: boolean): void {
        if (forceReload) {
            // Force a reload without asking the user
            console.log('Forcing a reload.');
            this.performHardRefresh();
        } else {
            console.log('User accepted the update. Reloading...');
            this.performHardRefresh();
        }
    }

    private performHardRefresh() {
        // Navigate to the root path
        //this.router.navigate(['/']).then(() => {
        window.location.reload();
        //});
    }
}

// Inline component for the update prompt dialog
@Component({
    selector: 'update-prompt-dialog',
    template: `
        <mat-dialog-content class="mat-typography">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Update Available</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <!-- Update Message -->
                    <p>A new version of the application is available. Do you want to update now?</p>
                </mat-card-content>
            </mat-card>
        </mat-dialog-content>
        <div mat-dialog-actions>
            <button mat-stroked-button color="warn" (click)="onCancel()">Cancel</button>
            <button mat-stroked-button color="primary" (click)="onConfirm()">Update</button>
        </div>
    `
})

export class UpdatePromptDialogComponent {
    constructor(public dialogRef: MatDialogRef<UpdatePromptDialogComponent>) { }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }
}

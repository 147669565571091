import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { AppSettings } from 'src/app/app.config';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: [],
})
export class BlankComponent implements OnInit {
  private htmlElement!: HTMLHtmlElement;

  options!: AppSettings;

  constructor(private settings: CoreService) {
    this.htmlElement = document.querySelector('html')!;
  }

  ngOnInit(): void {
    this.loadOptions();
  }

  loadOptions(): void {
    const savedOptions = localStorage.getItem('appSettings');
    if (savedOptions) {
      this.options = JSON.parse(savedOptions);
    } else {
      this.options = this.settings.getOptions();
    }
    this.receiveOptions(this.options);
  }

  receiveOptions(options: AppSettings): void {
    this.options = options;
    this.toggleDarkTheme(options);
  }

  toggleDarkTheme(options: AppSettings): void {
    if (options.theme === 'dark') {
      this.htmlElement.classList.add('dark-theme');
      this.htmlElement.classList.remove('light-theme');
    } else {
      this.htmlElement.classList.remove('dark-theme');
      this.htmlElement.classList.add('light-theme');
    }
  }
}

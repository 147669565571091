import { Injectable } from '@angular/core';
import { User } from '../models/user.model'

@Injectable({
    providedIn: 'root'
})
export class UserStorageService {
    constructor() { }

    // Save user data
    public saveUser(user: User): void {
        const userString = JSON.stringify(user);
        window.localStorage.removeItem('user');
        window.localStorage.setItem('user', userString);
    }

    // Get user data
    public getUser(): User | null {
        const userString = window.localStorage.getItem('user');

        return userString ? new User(JSON.parse(userString)) : null;
    }

    public deleteUser(): void {
        window.localStorage.removeItem('user');
    }

    public saveResult(value: string, key: string = 'resultValue'): void {
        window.localStorage.setItem(key, value);
    }

    // Get a simple string value
    public getResult(key: string = 'resultValue'): string | null {
        return window.localStorage.getItem(key);
    }

    // Delete a simple string value
    public deleteResult(key: string = 'resultValue'): void {
        window.localStorage.removeItem(key);
    }
}
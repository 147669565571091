// price.state.ts
export interface PriceState {
    platformTicketFee: any;
    vatOnPlatformFee: any;
    ticketPercentage: any;
  }
  
  export const initialPriceState: PriceState = {
    platformTicketFee: 0,
    vatOnPlatformFee: 0,
    ticketPercentage: 0,
  };
  
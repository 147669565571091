import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserStorageService } from '../storage/user-storage.service';

const TOKEN_HEADER_KEY = 'x-access-token'; // You can adjust this header key as needed

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userStorageService: UserStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    
    // Retrieve the token from UserStorageService
    const token = this.userStorageService.getUser()?.token;

    if (token) {
      // Clone the request to add the new header
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) });
    }

    // Pass on the cloned request instead of the original request
    return next.handle(authReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Process the response as needed or simply return it
          return event;
        }
        return event;
      })
    );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];

export interface AuthState {
    isAuthenticated: boolean;
}

export const initialState: AuthState = {
    isAuthenticated: false,
};

export interface DashBoardAuthState {
    isAuthenticated: boolean;
}

export const initialDashBoardAuthState: DashBoardAuthState = {
    isAuthenticated: false,
};
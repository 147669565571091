import { Component} from '@angular/core';


@Component({
  selector: 'app-page-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {


  constructor(
  ) {
 
  }


}

import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HTTP_INTERCEPTORS,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setDashboardAuthenticationStatus } from 'src/app/state/auth/auth.actions';
import { DashBoardAuthState } from 'src/app/state/auth/auth.state';
import { logout } from 'src/app/state/user/user.action';
import { UserStorageService } from '../storage/user-storage.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private snackBar: MatSnackBar, 
        private router: Router,
        private storeAuth: Store<DashBoardAuthState>,
        private userStorageService: UserStorageService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMessage = 'Unknown error occurred!';

                if (error.status === 401) {
                    if (error.error.message === 'No token provided!') {
                        errorMessage = 'Unauthorized: No token provided!';
                    } else if (error.error.message === 'Invalid access token.') {
                        errorMessage = 'Unauthorized: Invalid access token!';
                    } else if (error.error.message === 'Access token expired.') {
                        errorMessage = 'Unauthorized: Access token expired. Please Login Again!';
                        this.router.navigate(['/pages/login']);
                    }

                    // Display the error message using a Material Snackbar
                    this.snackBar.open(errorMessage, 'Close', {
                        duration: 10000, // Duration in milliseconds
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom',
                    });

                } else if (error.status === 403) {
                    if (error.error.message === 'Forbidden Access!') {
                        errorMessage = 'Forbidden: Access is denied!';
                    } else if (error.error.message === 'Access denied. Your account has been suspended.' || error.error.message === 'Account blocked!') {
                        errorMessage = 'Forbidden: Your account has been suspended!';
                        this.userStorageService.deleteUser();
                        // Dispatch the logout action to clear NgRx state
                        this.storeAuth.dispatch(logout());
                        //
                        this.storeAuth.dispatch(setDashboardAuthenticationStatus({ isAuthenticated: false }));
                        this.router.navigate(['/pages/error-suspended']);
                    } else if (error.error.message === 'Email not validated.') {
                        errorMessage = 'Forbidden: Email not validated!';
                    }

                    // Display the error message using a Material Snackbar
                    this.snackBar.open(errorMessage, 'Close', {
                        duration: 10000, // Duration in milliseconds
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom',
                    });
                }

                return next.handle(req);
            })
        );
    }
}

export const errorInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
];

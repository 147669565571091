// user.state.ts

export interface Roles {
    id: number;
    name: string;
}

export interface User {
    id: number;
    name: string;
    surname: string;
    email: string;
    photoUrl: string;
    emailValidated: boolean;
    accountType: number;
    linearReputation: any;
    isAboveAverage: any;
    automaticLimitIncrease: any;
    roles: Roles[];
    hasGoogle: boolean;
    hasPassword: boolean;
    wasInvited: boolean;
}

export interface UserPrivileges {
    emailLimit: any;
}

export interface UserPhone {
    phone: string;
    country: string;
    countryCode: string;
    validated: boolean;
    wasSmsSent: boolean;
    retry: number;
}

export interface DomainKey {
    selector: string;
    dkimSubdomain: string;
}

export interface UserDomain {
    id: number;
    domain: string;
    validated: boolean;
    domainKeys: DomainKey[]; // Array of DomainKey objects
    block?: boolean;
    reason?: string;
    ownStatus?: any;
    keepChecking?: any;
}

export interface AdminDomain {
    id: number;
    domain: string;
    validated: boolean;
    block?: boolean;
    reason?: string;
}

export interface UserSMTP {
    id: number;
    username: string;
}

export interface UserSenderDomain {
    domain: string;
}

export interface UserSender {
    id: number;
    from: string;
    username: string;
    replyTo: string;
    street: string;
    city: string;
    state: string;
    postCode: string;
    country: string;
    status: number;
    completeEmail: string;
}

export interface UserCredits {
    id: number;
    emailCredits?: number;
    spamTestCredits?: number;
    emailValidationCredits?: number;
    emailSendTestCredits?: number;
    cleanListCredits?: number;
}

export interface Recipient {
    recipientEmail: string;
    isBlocked?: boolean;
    reason?: string;
}

export interface UserEmailLogs {
    status?: number;
    recipientEmail: Recipient;
    postFixLogs?: string; // This field is optional
    wasOpen?: boolean;
    //from: string;
    subject?: string,
}

export interface AdminUserEmailLogs {
    status: number;
    statusText: string;
    recipientEmail: Recipient;
    motiveFail?: string; // This field is optional
    wasOpen: boolean;
    from: string;
    subject: string,
}

export interface UserAnalytics {
    sent: number,
    delivered: number,
    read: number,
    bounced: number,
    queueCount: number,
}

export interface UserRealTimeAnalytics {
    from: string,
    to: string,
    subject: string,
    status: number,
    updatedAt: any
}

export interface AccessControl {
    planId?: number,
    createdAt?: any,
    daysRemaining?: number,
    hoursRemaining?: number,
    minutesRemaining?: number
}

export interface TicketEvents {
    id: number,
    ticketName: string,
    quantity: number,
    sold: number,
    price: string,
    salesStartDate: any,
    salesStartTime: string,
    salesEndDate: any,
    salesEndTime: string,
    colorTitle: string,
    colorButton: string,
    eventId: number,
    createdAt: any,
    updatedAt: any
}

interface TicketDetails {
    vipTickets: number;
    quantity: number;
}

interface OrganizedTickets {
    vip: Record<string, TicketDetails>;
    nonVip: Record<string, TicketDetails>;
}

export interface UserEvents {
    id: any;
    eventId: string;
    name: string;
    status: number;
    createdAt: any;
    updatedAt: any;
    organizedTickets: OrganizedTickets;  // Add this line
    //ticketEvents: TicketEvents[];
}


export interface UserUnlayerDesigns {
    id?: any,
    name?: string,
    screenshot?: string,
    reference?: string,
}

export interface StockTemplates {
    id?: any,
    name?: string,
    screenshot?: string,
}

export interface UserContactList {
    id: any,
    name: string,
    quantity?: number,
    isProcessing: any,
    cleanedList: any
}

export interface UserEmailValidationList {
    id: any,
    name: string,
    isvalid?: number,
    isinvalid?: number,
    isProcessing: any
}

export interface UserCampaing {
    id: number,
    unlayerDesignId: number,
    emailSenderId: number,
    contactListId: number,
    status: number,
    reason: string,
    createdAt: any
}

export interface UserApiKey {
    id: number;
    name: string;
    key?: string;
    isValid?: boolean;
}

export interface AdminUserEmailSender {
    from: string;
    username: string;
    replyTo: string;
    subdomain: string;
    domain: string;
    completeEmail: string;
}

export interface AdminCampaign {
    id: number;
    designId: number;
    name: string;
    subject: string;
    status: number;
    cquantity: any;
    emailSender: AdminUserEmailSender;
}

export interface AdminEmailAnalysisGroup {
    user: AdminAnalyzedUser;
    totalAnalysis: number;
    emails: AdminEmailAnalysis[];
}

export interface AdminEmailAnalysis {
    id: number;
    trackQueueId: string;
    wasOpen: boolean;
    status: number;
    postFixLogs?: string | null;
    wasProcessed?: boolean;
    subject?: string | null;
    user?: AdminAnalyzedUser | null;
    analysis?: AdminEmailContentAnalysis | null;
}

export interface AdminAnalyzedUser {
    id: number;
    name: string;
    email: string;
    emailDomains: AdminEmailDomain[];
    privileges: any
}

export interface AdminEmailDomain {
    id: number;
    domain: string;
    validated: boolean;
    blocked: boolean;
    reason: string | null;
}

export interface AdminEmailContentAnalysis {
    htmlAnalysis: AdminHtmlAnalysis | null;
    imageAnalysis: AdminImageAnalysis | null;
    safetyChecks: AdminSafetyChecks | null;
    shortUrlChecks: AdminShortUrlChecks | null;
    brokenLinkChecks: AdminBrokenLinkChecks | null;
    allLinks: string[];
}

export interface AdminHtmlAnalysis {
    htmlSizeKB: string;
    textPercentage: string;
}

export interface AdminImageAnalysis {
    imageCount: number;
    altMissing: number;
    altMissingMessage: string;
}

export interface AdminSafetyChecks {
    isSafe: boolean;
    unsafeElements: string[];
}

export interface AdminShortUrlChecks {
    hasShortUrls: boolean;
    shortUrls: string[];
}

export interface AdminBrokenLinkChecks {
    hasPotentiallyBrokenLinks: boolean;
    potentiallyBrokenLinks: string[];
}


export interface UserHasMore {
    more: boolean;
}

export interface UserState {
    user: User | null;
    user_privileges: UserPrivileges | null;
    user_phones: UserPhone | null;
    user_domains: UserDomain[];
    user_smtp: UserSMTP[];
    user_sender: UserSender[];
    user_credits: UserCredits | null;
    user_email_logs: UserEmailLogs[];
    user_access_control: AccessControl | null;
    user_analytics: UserAnalytics[];
    user_analytics_real: UserRealTimeAnalytics[];

    user_events: UserEvents[];

    user_unlayer_designs: UserUnlayerDesigns[];
    user_contact_list: UserContactList[];

    user_email_validation_list: UserEmailValidationList[];

    user_api_keys: UserApiKey[]; // Add this line

    stock_templates: StockTemplates[];

    user_campaings: UserCampaing[];

    user_has_more: UserHasMore | null;

    admin_user_email_logs: AdminUserEmailLogs[];
    admin_domains: AdminDomain[];

    admin_campaigns: AdminCampaign[];

    admin_email_analysis: AdminEmailAnalysisGroup[];
}

export const initialUserState: UserState = {
    user: null,
    user_privileges: null,
    user_phones: null,
    user_domains: [],
    user_smtp: [],
    user_sender: [],
    user_credits: null,
    user_email_logs: [],
    user_access_control: null,
    user_analytics: [],
    user_analytics_real: [],

    user_events: [],

    user_api_keys: [], // Add this line

    user_unlayer_designs: [],
    user_contact_list: [],
    user_email_validation_list: [],

    user_has_more: null,

    stock_templates: [],

    user_campaings: [],

    admin_user_email_logs: [],
    admin_domains: [],
    admin_campaigns: [],
    admin_email_analysis: []
};

export class User {
    id: number = 0;
    email: string = '';
    token: string = '';
    permissions: string[] = [];
    photoUrl: string = '';

    constructor(data?: Partial<User>) {
        Object.assign(this, data);
    }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-tracking-pixel',
  template: '<img [src]="trackingPixelUrl" alt="tracking pixel" style="height:1px;width:1px;opacity:0;">',
})
export class TrackingPixelComponent implements OnInit {
  trackingPixelUrl: string = '';

  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const trackingId = params['trackingId'];
      this.trackingPixelUrl = `https://api.mailbit.io/administration/email-track/${trackingId}.png`;
      this.trackEmailOpen(trackingId);
    });
  }

  trackEmailOpen(trackingId: string): void {
    this.http.get(`https://api.mailbit.io/administration/email-track/${trackingId}.png`).subscribe(
      response => {
        console.log(`Tracking ID: ${trackingId} tracked successfully.`);
      },
      error => {
        console.error('Error tracking email open:', error);
      }
    );
  }
}

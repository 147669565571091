import { createReducer, on } from '@ngrx/store';
import { initialUserState } from './user.state';
import {
  storeUserData, updateUserDomainStatus, logout, addDomainSuccess,
  removeUserDomain,
  addSmtpSuccess,
  removeUserSmtp, addSenderSuccess, removeUserSender, updateSenderStatus, updateSpamCredit,
  updateDomainStatus, addUnlayerDesignSuccess, addContactListSuccess, updateUnlayerStatus
} from './user.action';

export const userReducer = createReducer(
  initialUserState,
  on(storeUserData, (state, { user, user_privileges, user_phones,
    user_domains, user_smtp, user_sender, user_credits,
    user_email_logs, user_access_control, user_analytics,
    user_analytics_real, admin_user_email_logs, admin_domains,
    user_api_keys,
    user_events, user_unlayer_designs, user_contact_list, user_email_validation_list, user_has_more, 
    stock_templates, user_campaings, admin_campaigns, admin_email_analysis }) => ({
      ...state,
      user: user ?? state.user,
      user_privileges: user_privileges ?? state.user_privileges,
      user_phones: user_phones ?? state.user_phones,
      user_domains: user_domains ?? state.user_domains,
      user_smtp: user_smtp ?? state.user_smtp,
      user_sender: user_sender ?? state.user_sender,
      user_credits: user_credits ?? state.user_credits,
      user_email_logs: user_email_logs ?? state.user_email_logs,
      user_access_control: user_access_control ?? state.user_access_control,
      user_analytics: user_analytics ?? state.user_analytics,
      user_analytics_real: user_analytics_real ?? state.user_analytics_real,

      user_events: user_events ?? state.user_events,

      user_api_keys: user_api_keys ?? state.user_api_keys,

      user_unlayer_designs: user_unlayer_designs ?? state.user_unlayer_designs,

      stock_templates: stock_templates ?? state.stock_templates,

      user_campaings: user_campaings ?? state.user_campaings,

      user_contact_list: user_contact_list ?? state.user_contact_list,

      user_email_validation_list: user_email_validation_list ?? state.user_email_validation_list,

      user_has_more: user_has_more ?? state.user_has_more,

      admin_user_email_logs: admin_user_email_logs ?? state.admin_user_email_logs,
      admin_domains: admin_domains ?? state.admin_domains,
      admin_campaigns: admin_campaigns ?? state.admin_campaigns,
      admin_email_analysis: admin_email_analysis ?? state.admin_email_analysis
    })),
  on(updateUserDomainStatus, (state, { id, validated }) => ({
    ...state,
    user_domains: state.user_domains.map(domain =>
      domain.id === id ? { ...domain, validated } : domain
    ),
  })),
  on(addDomainSuccess, (state, { domain }) => ({
    ...state,
    user_domains: [...state.user_domains, domain],
  })),
  on(removeUserDomain, (state, { id }) => ({
    ...state,
    user_domains: state.user_domains.filter(domain => domain.id !== id),
  })),
  on(addSmtpSuccess, (state, { smtp }) => ({
    ...state,
    user_smtp: [...state.user_smtp, smtp],
  })),
  on(removeUserSmtp, (state, { id }) => ({
    ...state,
    user_smtp: state.user_smtp.filter(smtp => smtp.id !== id),
  })),
  //
  on(addSenderSuccess, (state, { sender }) => ({
    ...state,
    user_sender: [...state.user_sender, sender],
  })),
  on(updateSenderStatus, (state, { id, validated }) => ({
    ...state,
    user_sender: state.user_sender.map(sender =>
      sender.id === id ? { ...sender, validated } : sender
    ),
  })),
  on(updateSpamCredit, (state, { spamTestCredits }) => ({
    ...state,
    user_credits: state.user_credits
      ? { ...state.user_credits, spamTestCredits }
      : state.user_credits,
  })),
  on(removeUserSender, (state, { id }) => ({
    ...state,
    user_sender: state.user_sender.filter(sender => sender.id !== id),
  })),
  on(updateDomainStatus, (state, { id, blocked, reason }) => ({
    ...state,
    admin_domains: state.admin_domains.map(domain =>
      domain.id === id ? { ...domain, blocked, reason } : domain
    ),
  })),
  on(addUnlayerDesignSuccess, (state, { unlayer }) => ({
    ...state,
    user_unlayer_designs: [...state.user_unlayer_designs, unlayer],
  })),
  //addContactListSuccess
  on(addContactListSuccess, (state, { user_contact_list }) => ({
    ...state,
    user_contact_list: [...state.user_contact_list, user_contact_list],
  })),
  on(updateUnlayerStatus, (state, { id, screenshot }) => ({
    ...state,
    user_unlayer_designs: state.user_unlayer_designs.map(unlayer =>
      unlayer.id === id ? { ...unlayer, screenshot: screenshot } : unlayer
    ),
  })),
  //
  on(logout, () => initialUserState) // Reset state on logout
);

import { environment } from "src/environments/environment";

export const API_ROUTES = {
  // Headers
  TOKEN_HEADER: "x-access-token",

  // API Base URLs
  API: environment.API,
  PAYMENT_API: environment.PAYMENT_API,

  // Frontend URL
  FRONT_URL: "https://mailbit.io/",

  // Email Placement
  GET_EMAIL_PLACEMENT_INBOX: "email/inbox/new",
  EMAIL_PLACEMENT_INBOX_CODE_SENT: "email/inbox/send",
  GET_RESULT_EMAIL_INBOX_RESULT: "email/inbox/status/:code",
  // Email Inbox
  GET_EMAIL_INBOX_AUTH: "dashboard/email/request/new",
  GET_RESULT_EMAIL_INBOX_AUTH: "dashboard/email/request/:email/result",

  // Email Inbox Requests
  GET_EMAIL_INBOX: "email/request/new",
  OPEN_EMAIL_INBOX: "email/request/inbox",
  GET_RESULT_EMAIL_INBOX: "email/request/:email/result",

  // Block List Check
  BLOCK_LIST_CHECK: "blocklist/check",

  // Access Count
  ACCESS_COUNT: "count/up",

  // Authentication
  AUTH_SIGNUP: "auth/sign-up",
  AUTH_SIGNUP_GOOGLE: "auth/sign-up/google",
  AUTH_SIGNIN_GOOGLE: "auth/sign-in/google",
  AUTH_SIGNIN: "auth/sign-in",
  AUTH_VERIFY_OTP: "auth/verify-code",
  AUTH_RESEND_OTP: "auth/resend-code",
  AUTH_TOKEN_VALIDATE: "auth/token-validate",

  // Notification
  NOTIFY_ME: "notify/sign-up",

  // Marketing Unsubscribe
  MARKETING_UNSUBSCRIBE: "marketing/list/unsubscribe",

  // Unsubscribe
  UNSUBSCRIBE: "list/unsubscribe",
  TEST_UNSUBSCRIBE: "list/test-unsubscribe",

  // Payment Intent
  CREATE_PAYMENT_INTENT: "payment/create-intent",
  CREATE_PAYMENT_INTENT_ONE_TIME: "payment/create-intent/onetime",
  CREATE_PAYMENT_INTENT_ONE_TIME_LIST: "payment/create-intent/onetime/list",
  CREATE_PAYMENT_INTENT_ONE_TIME_EXTRA_EMAILS: "payment/create-intent/onetime/extraemails",
  CREATE_PAYMENT_INTENT_TICKET: "payment/create-intent/ticket",

  // Ticket Management
  GET_TICKET_PRICE: "ticket/price",
  RESEND_TICKET: "ticket/resend",
  SCAN_TICKET: "ticket/scan",
  SEND_VIP_TICKET: "ticket/vip/send",

  // User Management
  GET_USER: "user/:userId:/get",
  GET_USER_ACTIVITY: "user/:userId:/activity",
  GET_USER_ACTIVITY_30: "user/:userId:/activity/30",
  GET_USER_TRACK_EMAIL: "user/track-email",
  SEARCH_USER_TRACK_EMAIL: "user/track-email/search",
  FILTER_USER_TRACK_EMAIL_BY_DATE: "user/track-email/bydate",

  // Admin - Email Logs
  ADMIN_EMAIL_LOGS_ERROR: "admin/email/users/log",

  // Admin - Domain Management
  ADMIN_NEW_DOMAINS: "admin/domains",
  ADMIN_CHECK_DOMAIN: "admin/domain/check",
  ADMIN_BLOCK_DOMAIN: "admin/domain/block",
  ADMIN_UNBLOCK_DOMAIN: "admin/domain/unblock",

  // Admin - Reports
  GENERATE_REPORTS: "admin/report/send",

  // Admin - Email Analysis
  ADMIN_EMAIL_ANTI_SPAM_FIND: "admin/list/email/anti-spam/find",
  ADMIN_EMAIL_ANTI_SPAM: "admin/list/email/anti-spam",
  ADMIN_EMAIL_ANALYSIS: "admin/list/email/analysis",
  ADMIN_EMAIL_ANALYSIS_FIND_BY_USER: "admin/start/analysis",
  ADMIN_EMAIL_DETAILS_USER: "admin/start/analysis/retrieve",
  ADMIN_EMAIL_MARK_AS_SUSPICIOUS: "admin/analysis/mark/suspicious",
  ADMIN_EMAIL_UNMARK_AS_SUSPICIOUS: "admin/analysis/unmark/suspicious",
  ADMIN_EMAIL_SUBMIT_SUBJECT_IDS: "admin/finish/analysis",
  ADMIN_EMAIL_BLOCK_USER: "admin/analysis/block/user",
  ADMIN_EMAIL_REMOVE_FROM_ANALYSIS_USER: "admin/analysis/remove/user",

  ADMIN_EMAIL_ANTI_SPAM_UNMARK: "admin/list/email/anti-spam/unmark",
  ADMIN_EMAIL_ANTI_SPAM_MARK: "admin/list/email/anti-spam/mark",

  // Admin - Server Management
  ADMIN_LIST_ALL_IPS: "admin/list/server/ips",
  ADMIN_CHANGE_FREE_IP: "admin/change/server/ip",
  ADMIN_CHANGE_STATUS_IP: "admin/change/server/ip/status",
  ADMIN_CHANGE_DEDICATED_IP: "admin/change/server/ip/dedicated",
  ADMIN_CHANGE_WARMED_IP: "admin/change/server/ip/warmed",
  ADMIN_IP_EXCHANGE: "admin/server/ip/exchange",

  // Admin - User Management
  ADMIN_USER_MANAGEMENT: "admin/user/find",
  ADMIN_USER_SETTINGS: "admin/user/update",

  // Admin - Campaigns
  ADMIN_NEW_CAMPAIGNS: "admin/campaign",
  ADMIN_NEW_CAMPAIGNS_VERIFY: "admin/campaign/verify",
  ADMIN_NEW_CAMPAIGNS_AUTHORIZE: "admin/campaign/authorize",

  // Admin - Unlayer Send To Production
  UNLAYER_SEND_TO_PRODUCTION: "admin/unlayer/production/send",

  // Domain Management
  ADD_DOMAIN: "domain/add",
  VERIFY_DOMAIN: "domain/verify",
  DELETE_DOMAIN: "domain/delete",
  UPDATE_DOMAIN: "domain/status",
  TEST_SEND_EMAIL_DOMAIN: "domain/send-test-email",

  // SMTP Management
  ADD_SMTP: "smtp/add",
  DELETE_SMTP: "smtp/delete",

  // API Key Management
  ADD_API_KEY: "apikey/add",
  DELETE_API_KEY: "apikey/delete",

  // Sender Management
  ADD_SENDER: "sender/add",
  DELETE_SENDER: "sender/delete",
  SENDER_AUTH_VERIFY_OTP: "sender/verify-code",
  SENDER_AUTH_RESEND_OTP: "sender/resend-code",

  // Unlayer Designs
  CREATE_UNLAYER_DESIGN: "unlayer/create",
  SAVE_UNLAYER_DESIGN: "unlayer/save",
  DELETE_UNLAYER_DESIGN: "unlayer/delete",
  GET_UNLAYER_DESIGN: "unlayer/get/all",
  GET_ONE_UNLAYER_DESIGN: "unlayer/:designId:",

  // Stock Templates
  GET_STOCK_TEMPLATE: "stock/get/all",
  GET_STOCK_CREATE_TEMPLATE: "stock/create",
  GET_STOCK_TEMPLATE_HTML: "stock/get/:designId:/html",
  GET_STOCK_TEMPLATE_ALL: "stock/all",
  STOCK_TEMPLATE_SAVE_HTML: "stock/save",
  GET_STOCK_TEMPLATE_INDUSTRY: "stock/all/industry",
  GET_STOCK_TEMPLATE_USAGE: "stock/all/usage",
  GET_STOCK_TEMPLATE_NAME: "stock/all/name",

  // Contact List Management
  CREATE_CONTACT_LIST: "contact/list/create",
  CREATE_EMAIL_VALIDATION_LIST: "email-validation/list/create",
  DELETE_CONTACT_LIST: "contact/list/delete",

  REAL_TIME_CONTACT_LIST: "contact/list/real-time",
  REAL_TIME_EMAIL_VALIDATION_LIST: "email-validation/list/real-time",

  // Campaign Management
  SEND_NEW_CAMPAING: "campaing/create",

  // Unlayer Email Test
  UNLAYER_EMAIL_TEST_SEND: "unlayer/email/test-send",

  // User Updates
  USER_UPDATE_NAME: "user/change/name",
  USER_CHANGE_PASSWORD: "user/change/password",
  USER_IS_SUBSCRIBED: "user/issubscribed/",

  INVITE_USER_SCANNER: "event/invite/scanner",

  // Subscription Management
  SUBSCRIPTION_CANCEL: "subscription/cancel",

  // Generate Email Report
  GENERATE_REPORT: "generate/user/report",
};
